import { Jurisdiction } from './jurisdiction';

/* eslint-disable camelcase */
export class Container {
  container_id: number;
  name: string;
  tax_year: number;
  period_start: string;
  period_end: string;
  is_interim_reporting_period: number;
  is_service: number;
  is_placeholder: number;
  tenant_id: number;
  archived: number;
  deleted: string;
  report_gen_full_access: boolean;
  allowed_jurisdictions?: Jurisdiction[];
  selected_jurisdictions?: Jurisdiction[];
  master_file_gen_access?: boolean;
  productName?: string;
  container_uuid?: any;
}