import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-confirmation-modal',
  templateUrl: './confirmation-modal.component.html'
})
export class ConfirmationModalComponent implements OnInit {

  title: string;
  text: string;
  result: Subject<boolean> = new Subject<boolean>();
 
  constructor(public bsModalRef: BsModalRef) {}
 
  ngOnInit() {
      
  }

  ngOnDestroy(): void {
    this.result.next(false);
  }

  confirm(): void {
    this.result.next(true);
    this.bsModalRef.hide();
  }
  
  cancel(): void {
    this.result.next(false);
    this.bsModalRef.hide();
  }
}