import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppConfig } from './app-config';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export abstract class BaseService<T> {
  baseUrl: string;
  commandCenterUrl: string;
  principleUrl: string;

  constructor(protected http: HttpClient, protected appConfig: AppConfig) {
    this.commandCenterUrl = this.appConfig.getCommandCenterUrl();
    this.principleUrl = this.appConfig.getPrincipleUrl();
    this.baseUrl = this.commandCenterUrl;
  }

  protected abstract getResourceUrl(): string;

  public get(id: string | number): Observable<any> {
    const encodedId = encodeURIComponent(String(id));
    return this.http.get<any>(`${this.getResourceUrl()}/${encodedId}`);
  }

  public list(queryString?: string): Observable<any> {
    let apiUrl = this.getResourceUrl();
    if (!!queryString) {
      apiUrl += queryString;
    }
    return this.http.get<any>(apiUrl);
  }

  public save(model: T): Observable<any> {
    return this.http.post(this.getResourceUrl(), model);
  }

}
