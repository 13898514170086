import { AppConfig } from './app-config';
import { BaseService } from './base.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { User } from '../models/user';

@Injectable({
  providedIn: 'root'
})
export class UserRoleService extends BaseService<User> {

  constructor(http: HttpClient, protected appConfig: AppConfig) {
    super(http, appConfig);
  }

  public getUserRoleByProduct(userUuid: string, productId: number|string): Observable<any> {
    const resourceUrl = `${this.getResourceUrl()}/${userUuid}/${productId}/byProduct`;
    return this.http.get(resourceUrl);
  }

  protected getResourceUrl(): string {
    return `${this.baseUrl}/v1/user-role`;
  }
}

