import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { User } from '../models/user';
import { UserService } from '../services/user.service';
import { Router } from '@angular/router';
import { FormControl } from '@angular/forms';
import { QueryString } from '../utils/queryString';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.css']
})
export class UsersComponent implements OnInit {

  users: User[] = [];
  subscriptions: Subscription[] = [];
  pageSizes = 20;
  totalItems: number;
  currentPage = 1;
  queryField: FormControl = new FormControl();
  filterString = '';
  columnSearchSelected = ''; // This is for the implementation of serach by columns
  currentOrder: string;
  isSorting: Boolean;
  sortColumn: string;

  constructor(
    private _userService: UserService,
    protected router: Router) { }

  ngOnInit() {
    this.queryField.valueChanges.pipe(
      debounceTime(500),
      distinctUntilChanged()
    ).subscribe(result => {
      this.currentPage = 1;
      this.filterString = encodeURIComponent(result);
      this.getAllUsers();
    });
  }

  goToCreateUser(): void {
    this.router.navigateByUrl(`/users/create`);
  }

  goToEditUser(userEmail: string): void {
    this.router.navigate(['/users', userEmail]);
  }

  pageChanged(event) {
    this.currentPage = event;
    this.getAllUsers();
  }

  sortBy(column) {
    this.currentPage = 1;
    this.isSorting = true;
    this.sortColumn = column;
    if (!this.currentOrder || this.currentOrder === 'desc') {
      this.currentOrder = 'asc';
    } else {
      this.currentOrder = 'desc';
    }
    this.getAllUsers();
  }

  private getAllUsers() {

    const query = `?${QueryString.getPagination([
      { variableName: 'length', value: this.pageSizes },
      { variableName: 'page', value: this.currentPage },
      { variableName: 'sort', value: this.sortColumn },
      { variableName: 'order', value: this.currentOrder },
      { variableName: 'search', value: this.filterString },
      { variableName: 'column', value: this.columnSearchSelected }
    ])}`;

    this.subscriptions.push(
      this._userService.list(query).subscribe((results) => {
        this.users = results.data.data;
        this.currentPage = results.data.page;
        this.totalItems = results.data.count;
        this.pageSizes = results.data.perPage;
        if (this.isSorting) {
          this.sortColumn = results.data.sortOptions.sort;
          this.currentOrder = results.data.sortOptions.order;
        }
      })
    );
  }
}
