import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BaseService } from './base.service';
import { Entity } from '../models/entity';
import { AppConfig } from './app-config';

@Injectable({
  providedIn: 'root'
})
export class MneService extends BaseService<Entity> {

  constructor(http: HttpClient, protected appConfig: AppConfig) {
    super(http, appConfig);
  }

  public deleteMne(entityId): Observable<any> {
    const resourceUrl = this.getResourceUrl() + '/' + entityId;

    return this.http.delete(resourceUrl);
  }

  public listEntityCurrency(): Observable<any> {
    const resourceUrl = `${this.baseUrl}/v1/currencies`;
    return this.http.get(resourceUrl);
  }

  public listEntityCountries(): Observable<any> {
    const resourceUrl = `${this.baseUrl}/v1/countries`;
    return this.http.get(resourceUrl);
  }

  public listEntityPrimaryFunctions(): Observable<any> {
    const resourceUrl = `${this.baseUrl}/v1/primary-functions`;
    return this.http.get(resourceUrl);
  }

  protected getResourceUrl(): string {
    return `${this.baseUrl}/v1/entity`;
  }

}
