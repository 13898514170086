import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'XBS Ops Center [DEV]';

  constructor(
    private _translate: TranslateService
  ) {
    // Set up UI multilanguge
    this._translate.setDefaultLang('en-US');
    // TODO: remove this later, as this value will be user-chosen in future.
    this._translate.use('en-US');
  }
}
