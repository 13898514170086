import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { BaseService } from './base.service';
import { WtpReview } from '../models/wtp-review';
import { AppConfig } from './app-config';

@Injectable({
  providedIn: 'root'
})
export class WtpReviewService extends BaseService<WtpReview> {

  constructor(http: HttpClient, protected appConfig: AppConfig) {
    super(http, appConfig);
  }

  public update(data: any, wtpReviewId: number): Observable<any> {
    const resourceUrl = `${this.getResourceUrl()}/${wtpReviewId}`;
    return this.http.patch(resourceUrl, data);
  }

  public delete(wtpReviewId: number): Observable<any> {
    const resourceUrl = `${this.getResourceUrl()}/${wtpReviewId}`;
    return this.http.delete(resourceUrl);
  }

  protected getResourceUrl(): string {
    return `${this.baseUrl}/v1/wtp/reviews`;
  }

}
