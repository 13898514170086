import { Component, OnInit } from '@angular/core';
import { Router, NavigationExtras, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';

import { Tenant } from '../../models/tenant';
import { Container } from '../../models/container';
import { User } from '../../models/user';
import { TenantService } from '../../services/tenant.service';
import { ContainerService } from '../../services/container.service';
import { MessageService } from 'src/app/services/message.service';
import { ModalService } from 'src/app/services/modal.service';

@Component({
  selector: 'app-tenant-detail',
  templateUrl: './tenant-detail.component.html',
  styleUrls: ['./tenant-detail.component.css']
})
export class TenantDetailComponent implements OnInit {

  tenantId: number;
  tenant: Tenant;
  users: User[];
  containers: Container[];
  isEdit = false;
  newTenantName = '';
  newTenantXbcode = '';
  subscriptions: Subscription[] = [];
  disableButtons: boolean = false;

  constructor(
    protected route: ActivatedRoute,
    protected router: Router,
    private _tenantService: TenantService,
    private _containerService: ContainerService,
    private modalService: ModalService,
    private messageService: MessageService
  ) { }

  ngOnInit() {
    this.tenantId = +this.route.snapshot.paramMap.get('tenantId');
    this.getTenant();
    this.getTenantUsers();
    this.getTenantContainers();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => {
      sub.unsubscribe();
    });
  }

  goToEditUser(userId: number): void {
    this.router.navigate(['/users', userId]);
  }

  editTenant(): void {
    this.newTenantName = this.tenant.name;
    this.newTenantXbcode = this.tenant.xbCode;
    this.isEdit = true;
  }

  cancelEdit(): void {
    this.newTenantName = this.tenant.name;
    this.newTenantXbcode = this.tenant.xbCode;
    this.isEdit = false;
  }

  saveEdit(): void {
    this.subscriptions.push(
      this._tenantService.update(this.tenantId, { name: this.newTenantName, xbCode: this.newTenantXbcode }).subscribe(() => {
        this.tenant.name = this.newTenantName;
        this.tenant.xbCode = this.newTenantXbcode;
        this.isEdit = false;
      })
    );
  }

  goToEditContainer(containerId: number): void {
    this.router.navigate(['/containers', containerId]);
  }

  goToCreateContainer(): void {
    const navigationExtras: NavigationExtras = {
      queryParams: { 'tenant_id': this.tenantId }
    };

    this.router.navigate(['/containers/create'], navigationExtras);
  }

  archiveContainer(containerId: number): void {
    this._containerService.archiveContainer(containerId).subscribe(() => {
      this.getTenantContainers();
    });
  }

  rollContainerTaxYearConfirmModal(containerId: number, currentTaxYear: number, direction: string): void {
    this.modalService.confirmationFionaModal(`Are you sure you want to roll this container ${direction}?`).subscribe(res =>{
      if (res) {
        const newTaxYear = this.calculateNewTaxYear(currentTaxYear, direction, 1);
        this.rollContainerTaxYear(containerId, newTaxYear);
      }
    });
  }

  rollContainerTaxYear(containerId: number, newTaxYear: number): void {
    this.disableButtons = true;

    this._containerService.rollContainerTaxYear(containerId, newTaxYear)
      .subscribe(()=> {
        this.disableButtons = false;
        this.messageService.displayMessage({
          type: 'message',
          body: 'Successfully rolled container tax year.'
        });
      }, ()=> {
        this.disableButtons = false;
        this.messageService.displayMessage({
          type: 'error',
          body: 'Error rolling container tax year.'
        });
      });
  }

  calculateNewTaxYear(currentTaxYear: number, direction: string, numberOfYears: number): number {
    return direction === 'forward' ? (currentTaxYear + numberOfYears) : (currentTaxYear - numberOfYears);
  }

  reportAccessChange(container: Container): void {
    this.disableButtons = true;
    
    this.subscriptions.push(
      this._containerService.toggleContainerReportAccess(container.container_id, container.report_gen_full_access).subscribe(() => {
        this.disableButtons = false;
      },
      () => {
        // eslint-disable-next-line camelcase
        container.report_gen_full_access = !container.report_gen_full_access;
        this.disableButtons = false;
      })
    );
  }

  private getTenant() {
    this.subscriptions.push(
      this._tenantService.get(this.tenantId).subscribe((results) => {
        this.tenant = results.data;
      })
    );
  }

  private getTenantUsers() {
    this.subscriptions.push(
      this._tenantService.listTenantUsers(this.tenantId).subscribe((results) => {
        this.users = results.data;
      })
    );
  }

  private getTenantContainers() {
    this.subscriptions.push(
      this._tenantService.listTenantContainers(this.tenantId).subscribe((results) => {
        this.containers = results.data;
      })
    );
  }
}
