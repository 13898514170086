import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TenantsComponent } from './tenants.component';
import { TenantDetailComponent } from './tenant-detail/tenant-detail.component';
import { TenantCreateComponent } from './tenant-create/tenant-create.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgxPaginationModule } from 'ngx-pagination';

@NgModule({
  declarations: [
    TenantsComponent,
    TenantDetailComponent,
    TenantCreateComponent,
  ],
  imports: [
    RouterModule,
    CommonModule,
    FormsModule,
    NgxPaginationModule,
    ReactiveFormsModule
  ]
})
export class TenantsModule { }
