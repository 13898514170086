import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { SessionService } from './session.service';
import { AppConfig } from './app-config';
import * as moment from 'moment';
import { AuthzGuardResponse } from '../models/config';

const checkAuthExpriationInteval = 1000 * 60 * 1;// 1 minute
const expiresSoonPeriod = 1000 * 60 * 20; // 20 minutes
@Injectable({
  providedIn: 'root'
})
export class AuthService {

  baseUrl: string;
  authUrl: string;
  loginStackUrl: string;
  lastRefreshedOn: moment.Moment;
  refreshTimerHandle;

  constructor(
    private _http: HttpClient,
    private _sessionService: SessionService,
    private _appConfig: AppConfig
  ) {
    this.authUrl = this._appConfig.getAuthUrl();
    this.baseUrl = this._appConfig.getCommandCenterUrl();
    this.loginStackUrl = `${window.location.origin}/${this._appConfig.getLoginStackRelativeUrl()}`;
    if (this.isLoggedIn()) {
      this.lastRefreshedOn = moment();
      this.startAuthRefreshTimer();
    }
  }

  public login(username: string, password: string): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    return this._http.post(`${this.baseUrl}/v1/auth/login`, { username, password }, { headers });
  }

  public logout(): void {
    if (this._sessionService.sessionExists()) {
      this._http.get<any>(`${this.authUrl}logout`).subscribe();
    }
    this._sessionService.emptySession();
    this.stopAuthRefreshTimer();
    window.location.replace(this.loginStackUrl);
  }

  public isLoggedIn(): boolean {
    const isLoggedIn = moment().isBefore(this.getExpiration());
    if (!isLoggedIn && this.isAuthRefreshTimerRunning()) {
      this.stopAuthRefreshTimer();
    }
    return isLoggedIn;
  }

  public async isAuthorized(): Promise<boolean> {
    return new Promise <boolean>((resolve, reject) => {
      this._http.get(this.getAuthzUrl()).subscribe((response: AuthzGuardResponse) => {
        resolve(response.authorized);
      }, () => {
        reject(false);
      });
    });
  }

  public isLoggedOut(): boolean {
    return this.isLoggedIn();
  }

  public refreshSession(callback: any): void {
    const refreshToken = this._sessionService.getRefreshToken();

    if (!refreshToken) {
      this.logout();
      return callback(null);
    } else {
      this._http.post<any>(`${this.authUrl}refresh`, { refreshToken })
        .subscribe(
          refreshRes => {
            this._sessionService.setAuthResult(refreshRes.data.idToken, refreshRes.data.accessToken);
            return callback(null);
          },
          err => callback(err)
        );
    }
  }

  startAuthRefreshTimer(): void {
    this.refreshTimerHandle = setTimeout(() => {
      if (this.lastRefreshedOn.isBefore(moment(), 'day')) {
        this.logout();
      } else {
        if (this.willExpireSoon()) {
          this.refreshSession((err) => {
            if (err) {
              this.logout();
            } else {
              this.lastRefreshedOn = moment();
            }
          });
        }
        this.startAuthRefreshTimer();
      }
    }, checkAuthExpriationInteval);
  }

  willExpireSoon(): boolean {
    return this.getExpiration().diff(moment(), 'milliseconds', true) < expiresSoonPeriod;
  }

  public getExpiration(): moment.Moment {
    const expiresAt = this._sessionService.getAuthExpiration();
    return moment.unix(expiresAt);
  }

  stopAuthRefreshTimer(): void {
    clearTimeout(this.refreshTimerHandle);
    this.refreshTimerHandle = null;
  }

  isAuthRefreshTimerRunning(): boolean {
    return !!this.refreshTimerHandle;
  }

  protected getAuthzUrl(): string {
    return `${this.baseUrl}/v1/authorization`;
  }
}
