import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { Container } from '../../models/container';
import { ContainerService } from '../../services/container.service';
import { Entity } from 'src/app/models/entity';

@Component({
  selector: 'app-container-detail',
  templateUrl: './container-detail.component.html',
  styleUrls: []
})
export class ContainerDetailComponent implements OnInit {

  containerId: string;
  container: Container;
  isContainerChanged: Boolean = false;
  entity: Entity;
  subscriptions: Subscription[] = [];

  constructor(
    private _containerService: ContainerService,
    protected route: ActivatedRoute,
    protected router: Router) { }

  ngOnInit() {
    this.containerId = this.route.snapshot.paramMap.get('containerId');
    this.getContainer();
  }

  private getContainer() {
    this.subscriptions.push(
      this._containerService.get(this.containerId).subscribe((results) => {
        this.container = results.data;
      })
    );
  }

  updateContainer(containerId: number): void {
    this.router.navigate(['/containers/edit', containerId]);
  }

}

