/* eslint-disable camelcase */

import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';

import { WtpReviewType } from '../../models/wtp-review-type';
import { WtpReviewTypeService } from '../../services/wtp-review-type.service';

@Component({
  selector: 'app-wtp-review-type-edit',
  templateUrl: './wtp-review-type-edit.component.html',
})
export class WtpReviewTypeEditComponent implements OnInit {

  wtp_review_type_id: number;
  wtpReviewType: WtpReviewType;
  name: string;
  description: string;
  error: any;

  subscriptions: Subscription[] = [];

  constructor(
    private _wtpReviewTypeService: WtpReviewTypeService,
    protected route: ActivatedRoute,
    protected router: Router
  ) { }

  ngOnInit() {
    this.wtp_review_type_id = +this.route.snapshot.paramMap.get('wtpReviewTypeId');
    this.getReviewType();
  }

  save(): void {
    const editWtpReviewType = {
      name: this.name,
      description: this.description,
    };
    this.subscriptions.push(
      this._wtpReviewTypeService.update(editWtpReviewType, this.wtp_review_type_id).subscribe(() => {
        this.router.navigate(['/wtp/types']);
      },
      ({ error }) => {
        // This is uh...fun?
        this.error = error.error;
      })
    );
  }

  private getReviewType(): void {
    this.subscriptions.push(
      this._wtpReviewTypeService.get(this.wtp_review_type_id).subscribe(({ data }) => {
        this.wtpReviewType = data;
        this.name = this.wtpReviewType.name;
        this.description = this.wtpReviewType.description;
      })
    );
  }

  goBack(): void {
    this.router.navigate(['/wtp/types']);
  }

}

