import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { Observable } from 'rxjs';
import { ProductNames } from '@xbs/xbs-enums';
import { AppConfig } from './app-config';

import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  loginStackUrl: string;

  constructor(private _authService: AuthService, private _appConfig: AppConfig) {
    this.loginStackUrl = `${window.location.origin}/${this._appConfig.getLoginStackRelativeUrl()}`;
  }

  canActivate(): Observable<boolean> | Promise<boolean> | boolean {
    if (this._authService.isLoggedIn()) {
      return true;
    }
    const productUuid = ProductNames.ByName.Admin.Uuid;
    localStorage.setItem('productUuid', productUuid);
    window.location.replace(this.loginStackUrl);
    return false;
  }

}
