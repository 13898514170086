import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormControl, FormGroup, ValidatorFn } from '@angular/forms';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { Subscription } from 'rxjs';

import { QueryString } from '../utils/queryString';
import { IdpService } from '../services/idp.service';
import { TenantService } from '../services/tenant.service';
import { Tenant } from '../models/tenant';
import { Validators } from '@angular/forms';
import { ModalService } from '../services/modal.service';
import { EditIdpModalComponent } from './edit-idp/edit-idp-modal.component';

@Component({
  selector: 'app-idp',
  templateUrl: './idp.component.html',
  styleUrls: ['./idp.component.css']
})
export class IdpComponent implements OnInit {

  idps: Array<any> = [];
  createIdpForm: FormGroup;
  queryField: FormControl = new FormControl();
  createIdpFile: File;
  createIdpFileName: string;
  subscriptions: Subscription[] = [];
  filterString = '';
  pageSize = 10;
  totalItems: number;
  currentPage = 1;
  currentOrder: string;
  isSorting: Boolean;
  sortColumn: string;
  columnSearchSelected = '';
  tenants: Tenant[];
  createIdpFileInputValidationFailed: boolean;
  domainExpession = /^[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9]\.[a-zA-Z]{2,}$/g;

  constructor(protected router: Router, protected _IdpService: IdpService, private _tenantService: TenantService, private _modalService: ModalService) { }

  ngOnInit() {
    this.initCreateIdpFormGroup();
    this.getAllIdps();
    this.queryField.valueChanges.pipe(debounceTime(500), distinctUntilChanged()).subscribe(result => {
      this.currentPage = 1;
      this.filterString = result;
      this.getAllIdps();
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => {
      sub.unsubscribe();
    });
  }

  goBack(): void {
    this.router.navigate(['/']);
  }

  initCreateIdpFormGroup() {
    this.createIdpForm = new FormGroup({
      createIdpTenantSearchFormControl: new FormControl(null, [Validators.required, this.validateSearchTenantCreateIdpForm()]),
      createIdpTenantSelected: new FormControl(null, [Validators.required]),
      createIdpDomainFormControl: new FormControl('', [Validators.required, this.validateCreateIdpDomain()]),
      createIdpFileFormControl: new FormControl(null, [Validators.required]),
      createIdpForceSSO: new FormControl(true)
    });
    this.tenants = [];
    this.createIdpFile = null;
    this.createIdpFileName = '';
    this.createIdpForm.controls.createIdpTenantSearchFormControl.valueChanges.pipe(
      debounceTime(500),
      distinctUntilChanged()
    ).subscribe(searchKeyword => {
      this.searchTenants(searchKeyword);
    });
  }

  onFileSelected(event) {
    this.createIdpFile = event.target.files[0];
    if (this.createIdpFile) {
      if (this.createIdpFile.type === 'text/xml') {
        this.createIdpFileName = this.createIdpFile.name;
        this.createIdpFileInputValidationFailed = false;
      } else {
        this.createIdpFileName = '';
        this.createIdpFileInputValidationFailed = true;
        this.createIdpFile = null;
        this.createIdpForm.controls.createIdpFileFormControl.markAsDirty();
      }
    }
  }

  createIDP() {
    const formData = new FormData();
    const formControls = this.createIdpForm.controls;
    formData.append('manifiest', this.createIdpFile);
    formData.append('idpName', formControls.createIdpDomainFormControl.value.trim());
    formData.append('tenantUuid', formControls.createIdpTenantSelected.value);
    formData.append('shouldForceSso', formControls.createIdpForceSSO.value ? '1' : '0');
    this.subscriptions.push(this._IdpService.registerIdp(formData).subscribe(() => {
      this.getAllIdps();
      this.initCreateIdpFormGroup();
    }));
  }

  getAllIdps(): void {
    const query = `?${QueryString.getPagination([
      { variableName: 'length', value: this.pageSize },
      { variableName: 'page', value: this.currentPage },
      { variableName: 'sort', value: this.sortColumn },
      { variableName: 'order', value: this.currentOrder },
      { variableName: 'search', value: encodeURIComponent(this.filterString) },
      { variableName: 'column', value: this.columnSearchSelected }
    ])}`;

    this.subscriptions.push(this._IdpService.getAllIdps(query).subscribe((response) => {
      this.idps = response.data;

      this.currentPage = response.page;
      this.totalItems = response.count;
      this.pageSize = response.perPage;

      if (this.isSorting) {
        this.sortColumn = response.sortOptions.sort;
        this.currentOrder = response.sortOptions.order;
      }
    }));
  }

  searchTenants(searchKeyword) {
    if (searchKeyword.length >= 3) {
      const query = `?${QueryString.getPagination([
        { variableName: 'length', value: 999 },
        { variableName: 'page', value: 1 },
        { variableName: 'search', value: encodeURIComponent(searchKeyword.trim()) },
      ])}`;
      this.subscriptions.push(
        this._tenantService.list(query).subscribe((results) => {
          this.createIdpForm.controls.createIdpTenantSelected.setValue(null);
          this.tenants = results.data.data;
        }
        ));
    } else {
      this.tenants = [];
    }
  }

  tenantSelected(value) {
    this.createIdpForm.controls.createIdpTenantSelected.setValue(value);
  }

  pageChanged(event) {
    this.currentPage = event;
    this.getAllIdps();
  }

  sortBy(column) {
    this.currentPage = 1;
    this.isSorting = true;
    this.sortColumn = column;
    if (!this.currentOrder || this.currentOrder === 'desc') {
      this.currentOrder = 'asc';
    } else {
      this.currentOrder = 'desc';
    }
    this.getAllIdps();
  }

  validateCreateIdpDomain(): ValidatorFn {
    return (idpDomain) => {
      const isValidDomain = idpDomain.value ? idpDomain.value.match(this.domainExpession) : false;
      return !!isValidDomain ? null : { invalidDomain: true }; ;
    };
  }

  validateSearchTenantCreateIdpForm(): ValidatorFn {
    return (tenantName) => {
      const isValidlength = tenantName.value && tenantName.value.length >= 3;
      if (!isValidlength) {
        if (this.createIdpForm && !isValidlength) {
          this.createIdpForm.controls.createIdpTenantSelected.setValue(null);
        }
        return !!isValidlength ? null : { invalidLength: true };
      }
    };
  }

  editIDP(idp) {
    const modalData = {
      idp,
      searchTenants: this.searchTenants,
      _tenantService: this._tenantService
    };
    this.subscriptions.push(this._modalService.customModal(EditIdpModalComponent, modalData).subscribe((updated)=>{
      if (updated) {
        this.getAllIdps();
      }
    }));
  }
}
