import { Injectable } from '@angular/core';

import { FileLoaderService } from './file-loader.service';
import { Config } from '../models/config';

const PrincipleApi = {
  develop: false,
  staging: false,
  production: false
};

@Injectable()
export class AppConfig {

  private static readonly CONFIG_FILE_LOCATION = 'assets/config/configuration.json';
  protected config: Config;

  constructor(private _fileLoader: FileLoaderService) { }

  public loadFromFile(): void {
    this.config = <Config> this._fileLoader.load(AppConfig.CONFIG_FILE_LOCATION);
  }

  public getCommandCenterUrl(): string {
    return this.config.commandCenterUrl;
  }

  public getPrincipleUrl(): string {
    return this.config.principleUrl;
  }

  public getProxyUrl(): string {
    return this.config.proxyUrl;
  }

  public getEnvironment(): string {
    return this.config.xbsEnvironment;
  }

  public getCurrentBuildTime(): string {
    return this.config.buildTime;
  }

  public getClientAppId(): string {
    return this.config.clientAppId;
  }

  public shouldUsePrincipleApi(): boolean {
    return PrincipleApi[this.getEnvironment()];
  }

  public getLoginStackRelativeUrl(): string {
    return this.config.loginStackRelativeUrl;
  }

  public getAuthUrl(): string {
    return this.config.authUrl;
  }

  // The current Launch Darkly implementation uses the Provision's environments
  public getLaunchDarklyId(): string {
    return this.config.launchDarklyId[this.config.xbsEnvironment];
  }
}
