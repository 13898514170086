/* eslint-disable camelcase */

import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';

import { WtpReviewService } from '../../services/wtp-review.service';
import { MneService } from '../../services/mne.service';
import { Country } from '../../models/country';
import { WtpReviewType } from '../../models/wtp-review-type';
import { WtpReviewTypeService } from '../../services/wtp-review-type.service';

@Component({
  selector: 'app-wtp-review-create',
  templateUrl: './wtp-review-create.component.html',
})
export class WtpReviewCreateComponent implements OnInit {

  wtp_review_type_id: number;
  value: string;
  country_id: number;
  error: any;

  countries: Country[] = [];
  wtpReviewTypes: WtpReviewType[] = [];

  subscriptions: Subscription[] = [];

  constructor(
    private _wtpReviewService: WtpReviewService,
    private _wtpReviewTypeService: WtpReviewTypeService,
    private _mneService: MneService,
    protected router: Router
  ) { }

  ngOnInit() {
    this.getCountries();
    this.getTypes();
  }

  save(): void {
    const newWtpReview = {
      wtp_review_type_id: this.wtp_review_type_id,
      value: this.value,
      country_id: this.country_id,
    };
    this.subscriptions.push(
      this._wtpReviewService.save(newWtpReview).subscribe(() => {
        this.router.navigate(['/wtp/reviews']);
      },
      ({ error }) => {
        // This is uh...fun?
        this.error = error.error;
      })
    );
  }

  private getCountries(): void {
    this.subscriptions.push(
      this._mneService.listEntityCountries().subscribe(({ data }) => {
        this.countries = data;
      })
    );
  }

  private getTypes(): void {
    this.subscriptions.push(
      this._wtpReviewTypeService.list().subscribe(({ data }) => {
        this.wtpReviewTypes = data;
      })
    );
  }

  goBack(): void {
    this.router.navigate(['/wtp/reviews']);
  }

}

