/* eslint-disable camelcase */

import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { ContainerService } from '../../services/container.service';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { NotificationService } from '../../services/notifications.service';
import { ProductService } from '../../services/product.service';

@Component({
  selector: 'app-container-create',
  templateUrl: './container-create.component.html',
  styleUrls: []
})
export class ContainerCreateComponent implements OnInit, OnDestroy {

  containerName: string;
  subscriptions: Subscription[] = [];
  productId: number;
  productInvalid: boolean;
  tenantId: number;
  isService = 1;
  isPlaceholder = 0;
  taxYear: number;
  reportAccess = false;
  selectedJurisdictions = [];
  jurisdictionsList = [];
  masterFileGenAccess: boolean;
  products: [];
  periodStart: string;
  periodEnd: string;
  isInterimReportingPeriod = 0;

  constructor(
    private _containerService: ContainerService,
    private _productService: ProductService,
    protected route: ActivatedRoute,
    protected router: Router,
    protected notificationService: NotificationService) {
  }

  ngOnInit() {
    this.tenantId = +this.route.snapshot.queryParamMap.get('tenant_id');
    this.getAllJurisdictions();
    this.getAllProducts();
  }

  save(): void {
    this.saveNew({
      name: this.containerName,
      tax_year: this.taxYear,
      period_start: this.periodStart,
      period_end: this.periodEnd,
      is_interim_reporting_period: this.isInterimReportingPeriod,
      is_service: this.isService,
      is_placeholder: this.isPlaceholder,
      productId: this.productId,
      tenant_id: this.tenantId,
      report_gen_full_access: this.reportAccess,
      master_file_gen_access: this.masterFileGenAccess,
      selected_jurisdictions: this.selectedJurisdictions
    });
  }

  selectAllJurisdictions() {
    this.selectedJurisdictions = this.jurisdictionsList;
  }

  deSelectAllJurisdictions() {
    this.selectedJurisdictions = [];
  }

  goBack(): void {
    this.router.navigate(['/tenants', this.tenantId]);
  }

  private saveNew(data): void {
    this.subscriptions.push(
      this._containerService.save(data)
        .subscribe(() => {
          this.router.navigate(['/tenants', this.tenantId]);
        })
    );
  }

  private getAllJurisdictions(): void {
    this._containerService.getAllJurisdictions().subscribe(
      results => {
        this.jurisdictionsList = results.data;
      }
    );
  }

  private getAllProducts(): void {
    this._productService.listProducts().subscribe(
      results => {
        this.products = results.data;
      }
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => {
      sub.unsubscribe();
    });
  }
}
