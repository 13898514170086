export enum CurrencyScale {
    FullValue,
    Hundredths,
    Thousandths,
    TenThousands,
    HundredThousands,
    Millionths,
    TenMillionths,
    HundredMillionths,
  }
  