import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { AuthService } from './auth.service';
import { AppConfig } from './app-config';

@Injectable({
  providedIn: 'root',
})
export class AuthzGuard implements CanActivate {
    loginStackUrl: string;

    constructor(private _authService: AuthService, private _appConfig: AppConfig) {
      this.loginStackUrl = `${window.location.origin}/${this._appConfig.getLoginStackRelativeUrl()}`;
    }

    async canActivate(): Promise<boolean> {
      return await this._authService.isAuthorized();
    }

}