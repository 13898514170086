import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-wtp-menu',
  templateUrl: './wtp-menu.component.html',
})
export class WtpMenuComponent implements OnInit {

  constructor(protected router: Router) { }

  ngOnInit() {
  }

  goReviews(): void {
    this.router.navigate([`/wtp/reviews`]);
  }

  goTypes(): void {
    this.router.navigate([`/wtp/types`]);
  }

}
