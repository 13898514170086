import {
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
  HttpErrorResponse,
  HttpHandler,
  HttpEvent
} from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { Injectable } from '@angular/core';
import { tap, catchError } from 'rxjs/operators';
import { MessageService } from '../../services/message.service';
import { Message } from '../../models/message';

@Injectable()
export class MessageInterceptor implements HttpInterceptor {
  constructor(private messageService: MessageService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      tap(event => {
        if (event instanceof HttpResponse) {
          const responsePayload = event.body;
          const message: Message = responsePayload.message;

          if (responsePayload.message) {
            this.messageService.displayMessage({
              type: 'message',
              body: message.body,
            });
          }
        }
      }),
      catchError(err => {
        if (err instanceof HttpErrorResponse) {
          const { message } = err.error;

          if (message) {
            this.messageService.displayMessage({
              type: 'error',
              body: message.body
            });
          }
        }

        return throwError(err);
      }));
  }
}
