import { Component, Input, OnInit } from '@angular/core';
import { Message } from '../../../../models/message';
import { MessageService } from '../../../../services/message.service';

@Component({
  selector: 'app-message-banner-content',
  templateUrl: './message-banner-content.component.html'
})
export class MessageBannerContentComponent implements OnInit {
  @Input() type: 'error' | 'message';
  @Input() messages: Message[];

  private icon: string;
  private iconContainerClass: string;

  constructor(private _messageService: MessageService) { }

  closeBanner() {
    this._messageService.closeBanner(this.type);
  }

  ngOnInit(): void {
    this.icon = this.type === 'message' ? 'far fa-check-square' : 'fas fa-exclamation-triangle';
    this.iconContainerClass = this.type === 'message' ? 'message-banner-icon-left-message' : 'message-banner-icon-left-error';
  }
}
