import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import * as moment from 'moment';
import { User } from '../models/user';

class LocalStorageKeys {
  public static AUTH_TOKEN = 'accessToken';
  public static EXPIRATION = 'expires';
  public static USER = 'user';
  public static ID_TOKEN = 'idToken';
  public static ACCESS_TOKEN = 'accessToken';
  public static REFRESH_TOKEN = 'refreshToken';
  public static REDIRECT_FLAG = 'redirectFlag';
}

@Injectable({
  providedIn: 'root'
})
export class SessionService {

  public helper: JwtHelperService;

  constructor() {
    this.helper = new JwtHelperService();
  }

  public getAuthToken(): string {
    return localStorage.getItem(LocalStorageKeys.AUTH_TOKEN);
  }

  public getIdToken(): string {
    return localStorage.getItem(LocalStorageKeys.ID_TOKEN);
  }

  public isTokenExpired(): boolean {
    return this.helper.isTokenExpired(this.getAuthToken());
  }

  public emptySession(): void {
    localStorage.removeItem(LocalStorageKeys.AUTH_TOKEN);
    localStorage.removeItem(LocalStorageKeys.EXPIRATION);
    localStorage.removeItem(LocalStorageKeys.ID_TOKEN);
  }

  public getUserDetails(): User {
    return <User>JSON.parse(localStorage.getItem(LocalStorageKeys.USER));
  }

  public setAuthResult(IdToken: any, AccessToken: any, refreshToken?: any): void {
    const decodedToken = this.helper.decodeToken(IdToken.jwtToken);
    const expiresAt = moment(decodedToken.exp);
    localStorage.setItem(LocalStorageKeys.ID_TOKEN, IdToken.jwtToken);
    localStorage.setItem(LocalStorageKeys.ACCESS_TOKEN, AccessToken.jwtToken);
    localStorage.setItem(LocalStorageKeys.EXPIRATION, JSON.stringify(expiresAt.valueOf()));
    if (refreshToken) {
      localStorage.setItem(LocalStorageKeys.REFRESH_TOKEN, refreshToken.token);
    }
  }

  public getAccessToken(): string {
    return localStorage.getItem(LocalStorageKeys.ACCESS_TOKEN);
  }

  public sessionExists(): boolean {
    return !!localStorage.getItem(LocalStorageKeys.ID_TOKEN);
  }

  public getRefreshToken(): string {
    return localStorage.getItem('refreshToken');
  }

  public getAuthExpiration(): any {
    return localStorage.getItem(LocalStorageKeys.EXPIRATION);
  }

}
