/* eslint-disable camelcase */

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BaseService } from './base.service';
import { User } from '../models/user';
import { ListResponse } from '../models/response';
import { Jurisdiction } from '../models/jurisdiction';
import { AppConfig } from './app-config';

@Injectable({
  providedIn: 'root'
})
export class UserService extends BaseService<User> {

  constructor(http: HttpClient, protected appConfig: AppConfig) {
    super(http, appConfig);
  }

  public listUserContainers(userId: string): Observable<any> {
    const encodedId = encodeURIComponent(userId);
    const resourceUrl = `${this.getResourceUrl()}/${encodedId}/containers`;
    return this.http.get(resourceUrl);
  }

  public createUserContainer(user_id, user_uuid, container): Observable<any> {
    const encodedId = encodeURIComponent(user_id);
    const resourceUrl = `${this.getResourceUrl()}/${encodedId}/containers`;
    return this.http.post(resourceUrl, { user_id, user_uuid, container });
  }

  public createCognitoUser(userId): Observable<any> {
    const encodedId = encodeURIComponent(userId);
    const resourceUrl = `${this.getResourceUrl()}/${encodedId}/cognito`;
    return this.http.post(resourceUrl, { userId });
  }

  public getAllJurisdictions(): Observable<ListResponse<Jurisdiction>> {
    return this.http.get<ListResponse<Jurisdiction>>(`${this.baseUrl}/v1/countries/jurisdictions`);
  }

  public getJurisdictionsByProduct(userUuid, productUuid): Observable<any> {
    const url = `${this.getResourceUrl()}/${userUuid}/jurisdictions?productUuid=${productUuid}`;
    return this.http.get(url);
  }

  public updateJurisidictionsByProduct(userUuid: string, productName: string, productUuid: string, jurisdictions: Jurisdiction[]): Observable<any> {
    const body = {
      productUuid: productUuid,
      jurisdictions: jurisdictions.map(jurisdiction => jurisdiction.isoCode ? jurisdiction.isoCode : jurisdiction),
    };
    const url = `${this.getResourceUrl()}/${userUuid}/jurisdictions`;
    return this.http.put(url, body);
  }

  public deleteUserContainer(user_id: string, user_uuid, container): Observable<any> {
    const resourceUrl = `${this.getResourceUrl()}/actions/deleteusercontainer`;
    return this.http.patch(resourceUrl, { user_id, user_uuid, container });
  }

  public disableUser(userId: string, email: string): Observable<any> {
    const encodedId = encodeURIComponent(userId);
    const resourceUrl = `${this.getResourceUrl()}/${encodedId}/disable`;
    const payload = {
      username: email
    };
    return this.http.put(resourceUrl, payload);
  }

  public enableUser(userId: string, email: string): Observable<any> {
    const encodedId = encodeURIComponent(userId);
    const resourceUrl = `${this.getResourceUrl()}/${encodedId}/enable`;
    const payload = {
      username: email
    };
    return this.http.put(resourceUrl, payload);
  }

  public resetPassword(email: string): Observable<any> {
    const resourceUrl = `${this.getResourceUrl()}/resetPassword`;
    const payload = {
      username: email
    };
    return this.http.put(resourceUrl, payload);
  }

  public updateUserRole(userId: string, roleUuid: string, userUuid: string): Observable<any> {
    const encodedId = encodeURIComponent(userId);
    const resourceUrl = `${this.getResourceUrl()}/${encodedId}/role`;
    const payload = {
      roleUuid,
      userUuid
    };
    return this.http.put(resourceUrl, payload);
  }

  public createUserRole(userId: string, roleUuid: string, productUuid: string, userUuid: string): Observable<any> {
    const encodedId = encodeURIComponent(userId);
    const resourceUrl = `${this.getResourceUrl()}/${encodedId}/role`;
    const payload = {
      productUuid,
      roleUuid,
      userUuid
    };
    return this.http.post(resourceUrl, payload);
  }
  public getEulaByProduct(userId: string, productId: number | string): Observable<any> {
    const encodedId = encodeURIComponent(userId);
    const url = `${this.getResourceUrl()}/${encodedId}/eula/${productId}`;
    return this.http.get(url);
  }
  protected getResourceUrl(): string {
    return `${this.baseUrl}/v1/user`;
  }

}
