import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthzGuard } from './services/authz-guard.service';
import { AuthGuard } from './services/auth-guard.service';
import { LandingComponent } from './landing/landing.component';
import { LoginComponent } from './login/login.component';

import { HomeComponent } from './home/home.component';
/* Tenant Mgmt */
import { TenantsComponent } from './tenants/tenants.component';
import { TenantDetailComponent } from './tenants/tenant-detail/tenant-detail.component';

import { TenantCreateComponent } from './tenants/tenant-create/tenant-create.component';
/* User Mgmt */
import { UsersComponent } from './users/users.component';
import { UserDetailComponent } from './users/user-detail/user-detail.component';

import { UserCreateComponent } from './users/user-create/user-create.component';
/* Container Mgmt */
import { ContainerDetailComponent } from './container/container-detail/container-detail.component';

import { ContainerCreateComponent } from './container/container-create/container-create.component';
/* MNE Mgmt */

import { MneCreateComponent } from './mne/mne-create/mne-create.component';
/* WTP Management */
import { WtpMenuComponent } from './wtp/wtp-menu/wtp-menu.component';
import { WtpReviewComponent } from './wtp/wtp-review/wtp-review.component';
import { WtpReviewCreateComponent } from './wtp/wtp-review-create/wtp-review-create.component';
import { WtpReviewEditComponent } from './wtp/wtp-review-edit/wtp-review-edit.component';
import { WtpReviewTypeComponent } from './wtp/wtp-review-type/wtp-review-type.component';
import { WtpReviewTypeCreateComponent } from './wtp/wtp-review-type-create/wtp-review-type-create.component';
import { WtpReviewTypeEditComponent } from './wtp/wtp-review-type-edit/wtp-review-type-edit.component';
import { ContainerEditComponent } from './container/container-edit/container-edit.component';
import { IdpComponent } from './idp/idp.component';

const routes: Routes = [
  {
    path: 'login-ui',
    component: LoginComponent
  },
  {
    path: '',
    component: LandingComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        component: HomeComponent,
        canActivate: [AuthzGuard],
      },
      {
        path: 'idp',
        component: IdpComponent,
      },
      {
        path: 'tenants',
        component: TenantsComponent,
      },
      {
        path: 'tenants/create',
        component: TenantCreateComponent,
      },
      {
        path: 'tenants/:tenantId',
        component: TenantDetailComponent,
      },
      {
        path: 'users',
        component: UsersComponent,
      },
      {
        path: 'users/create',
        component: UserCreateComponent,
      },
      {
        path: 'users/:userId',
        component: UserDetailComponent,
      },
      {
        path: 'containers/create',
        component: ContainerCreateComponent,
      },
      {
        path: 'containers/:containerId',
        component: ContainerDetailComponent,
      },
      {
        path: 'containers/edit/:containerId',
        component: ContainerEditComponent,
      },
      {
        path: 'mne/create',
        component: MneCreateComponent,
      },
      {
        path: 'wtp',
        component: WtpMenuComponent,
      },
      {
        path: 'wtp/reviews',
        component: WtpReviewComponent,
      },
      {
        path: 'wtp/reviews/create',
        component: WtpReviewCreateComponent,
      },
      {
        path: 'wtp/reviews/edit/:wtpReviewId',
        component: WtpReviewEditComponent,
      },
      {
        path: 'wtp/types',
        component: WtpReviewTypeComponent,
      },
      {
        path: 'wtp/types/create',
        component: WtpReviewTypeCreateComponent,
      },
      {
        path: 'wtp/types/edit/:wtpReviewTypeId',
        component: WtpReviewTypeEditComponent,
      },

    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)], // tart the router via "RouterModule.forRoot(routes)"
  exports: [RouterModule]
})
export class AppRoutingModule { }
