import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';

import { Router } from '@angular/router';
import { WtpReview } from '../../models/wtp-review';
import { WtpReviewService } from '../../services/wtp-review.service';

@Component({
  selector: 'app-wtp-review',
  templateUrl: './wtp-review.component.html',
  styleUrls: ['./wtp-review.component.css']
})
export class WtpReviewComponent implements OnInit {

  reviews: WtpReview[] = [];
  subscriptions: Subscription[] = [];
  error: any;

  constructor(
    private _wtpReviewService: WtpReviewService,
    protected router: Router
  ) {}

  ngOnInit() {
    this.getAllWtpReviews();
  }

  goToCreateWtpReview(): void {
    this.router.navigateByUrl(`/wtp/reviews/create`);
  }

  goToEditWtpReview(wtpReviewId: number): void {
    this.router.navigate(['/wtp/reviews/edit', wtpReviewId]);
  }

  removeWtpReview(wtpReviewId: number): void {
    this.subscriptions.push(
      this._wtpReviewService.delete(wtpReviewId).subscribe(() => {
        this.getAllWtpReviews();
      },
      ({ error }) => {
        // This is uh...fun?
        this.error = error.error;
      })
    );
  }

  private getAllWtpReviews() {
    this.subscriptions.push(
      this._wtpReviewService.list('').subscribe((results) => {
        this.reviews = results.data;
      })
    );
  }

}
