import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { IdpService } from 'src/app/services/idp.service';

@Component({
  selector: 'edit-idp-modal',
  templateUrl: './edit-idp-modal.component.html'
})
export class EditIdpModalComponent implements OnInit {

  idp: any;
  createIdpForm: FormGroup;
  tenants: string;
  searchTenants: Function;
  subscriptions: Subscription[] = [];
  result: Subject<any> = new Subject<any>();
  initalState;
  enableFormSaveAction: boolean;

  constructor(public bsModalRef: BsModalRef, public _idpService: IdpService) {}

  ngOnInit() {
    this.enableFormSaveAction = false;
    this.createIdpForm = new FormGroup({
      createIdpTenantSearchFormControl: new FormControl(this.idp.tenantName, [Validators.required]),
      createIdpTenantSelected: new FormControl(this.idp.tenantUuid, [Validators.required]),
      createIdpForceSSO: new FormControl(!!this.idp.forceSso)
    });

    this.initalState = {
      createIdpForceSSO: this.createIdpForm.controls.createIdpForceSSO.value,
      createIdpTenantSelected: this.createIdpForm.controls.createIdpTenantSelected.value,
    };

    this.createIdpForm.controls.createIdpTenantSearchFormControl.valueChanges.pipe(
      debounceTime(500),
      distinctUntilChanged()
    ).subscribe(searchKeyword => {
      this.searchTenants(searchKeyword);
    });
    this.subscriptions.push(this.createIdpForm.valueChanges.subscribe(newValues=>{
      this.enableFormCheck(newValues);
    }));

  }
  enableFormCheck(newValues) {
    const forceSsoAltered = newValues.createIdpForceSSO !== this.initalState.createIdpForceSSO;
    const tenantSelectedAltered = newValues.createIdpTenantSelected !== this.initalState.createIdpTenantSelected && newValues.createIdpTenantSelected !== null;
    this.enableFormSaveAction = forceSsoAltered || tenantSelectedAltered;
  }

  tenantSelected(tenant): void {
    this.createIdpForm.controls.createIdpTenantSelected.setValue(tenant);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => {
      sub.unsubscribe();
    });
  }

  confirm(): void {
    const newConfiguration = {
      domain: this.idp.domain,
      forceSso: this.createIdpForm.controls.createIdpForceSSO.value ? 1 : 0,
      newTenantUuid: this.createIdpForm.controls.createIdpTenantSelected.value,
      originalTenantUuid: this.idp.tenantUuid
    };
    this.subscriptions.push(this._idpService.updateIdpSsoConfiguration(newConfiguration).subscribe(()=>{
      this.removeSubscriptions();
      this.result.next(newConfiguration);
      this.bsModalRef.hide();
    }));
  }

  cancel(): void {
    this.removeSubscriptions();
    this.result.next(false);
    this.bsModalRef.hide();
  }

  removeSubscriptions() {
    this.subscriptions.forEach(sub => {
      sub.unsubscribe();
    });
  }
}