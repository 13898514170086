import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-confirm-modal-form',
  templateUrl: './confirm-modal-form.component.html'
})
export class ConfirmModalFormComponent implements OnInit {

  title: string;
  confirmationText: string;
  confirmationInput: string;
  result: Subject<boolean> = new Subject<boolean>();
 
  constructor(public bsModalRef: BsModalRef) {}
 
  ngOnInit() {
    this.confirmationText = 'Fiona';
  }

  ngOnDestroy(): void {
    this.result.next(false);
  }

  confirm(): void {
    this.result.next(true);
    this.bsModalRef.hide();
  }
  
  cancel(): void {
    this.result.next(false);
    this.bsModalRef.hide();
  }

  isValidConfirmationInput(): boolean {
    let exp = new RegExp('^' + this.confirmationInput + '$', 'i');
    return exp.test(this.confirmationText);
  }
}