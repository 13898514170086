import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { Message, MessageAction } from '../models/message';

@Injectable({ providedIn: 'root' })
export class MessageService {
  private subject = new Subject<MessageAction>();
  private timeouts = { error: [], message: [] };

  private clearTimeouts(type: 'error' | 'message') {
    const timeoutsToClear = this.timeouts[type];

    timeoutsToClear.forEach(timeout => {
      clearTimeout(timeout);
    });
  }

  displayMessage(message: Message, timeoutToHide = 5000) {
    this.subject.next({ command: 'displayMessage', payload: message });

    const messageTimeout = setTimeout(() => {
      this.subject.next({ command: 'clearMessage' });
    }, timeoutToHide);

    this.timeouts[message.type].push(messageTimeout);
  }

  closeBanner(bannerType: 'error' | 'message') {
    this.subject.next({ command: 'closeBanner', payload: bannerType });

    this.clearTimeouts(bannerType);
  }

  getMessage(): Observable<MessageAction> {
    return this.subject.asObservable();
  }
}
