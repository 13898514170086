import { Component, OnInit, OnDestroy } from '@angular/core';
import { HostListener } from '@angular/core';
import { Subscription } from 'rxjs';
import { each } from 'lodash';

import { Router } from '@angular/router';
import { WtpReviewType } from '../../models/wtp-review-type';
import { WtpReviewTypeService } from '../../services/wtp-review-type.service';
import { NotificationService } from '../../services/notifications.service';

@Component({
  selector: 'app-wtp-review-type',
  templateUrl: './wtp-review-type.component.html',
})
export class WtpReviewTypeComponent implements OnInit, OnDestroy {

  types: WtpReviewType[] = [];
  subscriptions: Subscription[] = [];
  error: any;

  constructor(
    private _notificationService: NotificationService,
    private _wtpReviewTypeService: WtpReviewTypeService,
    protected router: Router
  ) {}

  @HostListener('click') onClick() {
    this.resetErrors();
  }

  ngOnInit() {
    this.listenForErrorMessages();
    this.getAllWtpReviewTypes();
  }

  ngOnDestroy(): void {
    each(this.subscriptions, (sub: Subscription) => {
      sub.unsubscribe();
    });
  }

  public resetErrors(): void {
    this.error = null;
  }

  protected listenForErrorMessages(): void {
    this.subscriptions.push(
      this._notificationService.notification$.subscribe((message) => {
        this.error = message.error.error;
      })
    );
  }

  goToCreateWtpReviewType(): void {
    this.router.navigateByUrl(`/wtp/types/create`);
  }

  goToEditWtpReviewType(wtpReviewId: number): void {
    this.router.navigate(['/wtp/types/edit', wtpReviewId]);
  }

  removeWtpReviewType(wtpReviewId: number): void {
    this.subscriptions.push(
      this._wtpReviewTypeService.delete(wtpReviewId).subscribe(
        () => {
          this.getAllWtpReviewTypes();
        },
        ({ error }) => {
        // This is uh...fun?
          this.error = error.error;
        })
    );
  }

  private getAllWtpReviewTypes() {
    this.subscriptions.push(
      this._wtpReviewTypeService.list('').subscribe((results) => {
        this.types = results.data;
      })
    );
  }

}
