import { Injectable } from '@angular/core';

@Injectable()
export class FileLoaderService {

  // loads JSON file from a local URL
  public load(fileLocation: string): any {
    const xobj = new XMLHttpRequest();
    xobj.overrideMimeType('application/json');
    xobj.open('GET', fileLocation, false);
    xobj.send(null);

    return JSON.parse(xobj.responseText);
  }
}
