import { Injectable } from '@angular/core';
import LaunchDarkly, { LDClient, LDFlagChangeset, LDFlagSet } from 'launchdarkly-js-client-sdk';
import { Subject } from 'rxjs';
import { AppConfig } from './app-config';

@Injectable({ providedIn: 'root' })
export class LaunchDarklyService {
  ldClient: LDClient;
  flags: LDFlagSet;
  flagChange: Subject<Object> = new Subject<Object>();

  constructor(private _appConfig: AppConfig) {
    this.flags = {};

    this.ldClient = LaunchDarkly.initialize(
      this._appConfig.getLaunchDarklyId(),
      {
        kind: 'user',
        key: 'adminAppUser'
      }
    );

    this.ldClient.on('change', (updatedFlags: LDFlagChangeset) => {
      Object.keys(updatedFlags).forEach((flagName) => {
        if (updatedFlags[flagName] !== undefined) {
          this.flags[flagName] = updatedFlags[flagName].current;
        }
      });

      this.flagChange.next(this.flags);
    });

    this.ldClient.on('ready', () => {
      this.flags = this.ldClient.allFlags();
    });
  }
}
