/* eslint-disable camelcase */

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BaseService } from './base.service';
import { User } from '../models/user';
import { AppConfig } from './app-config';

@Injectable({
  providedIn: 'root'
})
export class ProductService extends BaseService<User> {

  constructor(http: HttpClient, protected appConfig: AppConfig) {
    super(http, appConfig);
  }

  public listProducts(): Observable<any> {
    const resourceUrl = `${this.getResourceUrl()}`;
    return this.http.get(resourceUrl);
  }

  protected getResourceUrl(): string {
    return `${this.baseUrl}/v1/product`;
  }

}
