import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { Tenant } from '../models/tenant';
import { TenantService } from '../services/tenant.service';
import { Router } from '@angular/router';
import { FormControl } from '@angular/forms';
import { QueryString } from '../utils/queryString';

@Component({
  selector: 'app-tenants',
  templateUrl: './tenants.component.html',
})
export class TenantsComponent implements OnInit {

  tenants: Tenant[] = [];
  subscriptions: Subscription[] = [];
  pageSizes = 20;
  totalItems: number;
  currentPage = 1;
  currentOrder: string;
  isSorting: Boolean;
  sortColumn: string;
  queryField: FormControl = new FormControl();
  filterString = '';
  columnSearchSelected = ''; // This is for the implementation of serach by columns

  constructor(
    private _tenantService: TenantService,
    protected router: Router) { }

  ngOnInit() {
    this.getAllTenants();
    this.queryField.valueChanges.pipe(debounceTime(500), distinctUntilChanged()).subscribe(result => {
      this.currentPage = 1;
      this.filterString = result;
      this.getAllTenants();
    });
  }

  goToCreateTenant(): void {
    this.router.navigateByUrl(`/tenants/create`);
  }

  goToEditTenant(tenantId: number): void {
    this.router.navigate(['/tenants', tenantId]);
  }

  pageChanged(event) {
    this.currentPage = event;
    this.getAllTenants();
  }

  sortBy(column) {
    this.currentPage = 1;
    this.isSorting = true;
    this.sortColumn = column;
    if (!this.currentOrder || this.currentOrder === 'desc') {
      this.currentOrder = 'asc';
    } else {
      this.currentOrder = 'desc';
    }
    this.getAllTenants();
  }

  private getAllTenants() {
    const query = `?${QueryString.getPagination([
      { variableName: 'length', value: this.pageSizes },
      { variableName: 'page', value: this.currentPage },
      { variableName: 'sort', value: this.sortColumn },
      { variableName: 'order', value: this.currentOrder },
      { variableName: 'search', value: encodeURIComponent(this.filterString) },
      { variableName: 'column', value: this.columnSearchSelected }
    ])}`;
    this.subscriptions.push(
      this._tenantService.list(query).subscribe((results) => {
        this.tenants = results.data.data;
        this.currentPage = results.data.page;
        this.totalItems = results.data.count;
        this.pageSizes = results.data.perPage;
        if (this.isSorting) {
          this.sortColumn = results.data.sortOptions.sort;
          this.currentOrder = results.data.sortOptions.order;
        }
      })
    );
  }
}
