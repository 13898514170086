import { Injectable } from '@angular/core';

import { Observable, Subject } from 'rxjs';

@Injectable()
export class NotificationService {

  private _notification: Subject<any> = new Subject<any>();
  public notification$: Observable<any> = this._notification.asObservable();

  public notify(message) {
    this._notification.next(message);
  }
}
