import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';

import { WtpReviewTypeService } from '../../services/wtp-review-type.service';

@Component({
  selector: 'app-wtp-review-type-create',
  templateUrl: './wtp-review-type-create.component.html',
})
export class WtpReviewTypeCreateComponent implements OnInit {

  name: string;
  description: string;
  error: any;

  subscriptions: Subscription[] = [];

  constructor(
    private _wtpReviewTypeService: WtpReviewTypeService,
    protected router: Router
  ) { }

  ngOnInit() {
  }

  save(): void {
    const newWtpReviewType = {
      name: this.name,
      description: this.description,
    };
    this.subscriptions.push(
      this._wtpReviewTypeService.save(newWtpReviewType).subscribe(() => {
        this.router.navigate(['/wtp/types']);
      },
      ({ error }) => {
        // This is uh...fun?
        this.error = error.error;
      })
    );
  }

  goBack(): void {
    this.router.navigate(['/wtp/types']);
  }

}

