import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { TenantService } from '../../services/tenant.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-tenant-create',
  templateUrl: './tenant-create.component.html',
  styleUrls: []
})
export class TenantCreateComponent implements OnInit {

  tenantName: string;
  enabled = true;
  tenantXbcode: string;
  subscriptions: Subscription[] = [];

  constructor(
    private _tenantService: TenantService,
    protected router: Router) { }

  ngOnInit() {
  }

  save(): void {
    this.saveNew({
      name: this.tenantName,
      xbCode: this.tenantXbcode,
      enabled: this.enabled,
    });
  }

  private saveNew(data): void {
    this.subscriptions.push(
      this._tenantService.save(data)
        .subscribe(() => {
          this.router.navigate(['/tenants']);
        })
    );
  }

  goBack(): void {
    this.router.navigate(['/tenants']);
  }

}
