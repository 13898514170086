/* eslint-disable camelcase */

import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';
import { Router, ActivatedRoute } from '@angular/router';

import { ContainerService } from '../../services/container.service';
import { Container } from '../../models/container';
import { ListResponse, ObjectResponse } from 'src/app/models/response';
import { ModalService } from '../../services/modal.service';
import { NotificationService } from '../../services/notifications.service';
import { Jurisdiction } from 'src/app/models/jurisdiction';

@Component({
  selector: 'app-container-edit',
  templateUrl: './container-edit.component.html',
})
export class ContainerEditComponent implements OnInit {
  containerId: number;
  container = new Container();
  error: any;
  selectedJurisdictions: Jurisdiction[] = [];
  jurisdictionsListToDisplay: Jurisdiction[] = [];
  fullJurisdictionsList: Jurisdiction[] = [];
  allowedJurisdictions: Jurisdiction[] = [];

  constructor(
    private _containerService: ContainerService,
    private modalService: ModalService,
    protected route: ActivatedRoute,
    protected router: Router,
    protected notificationService: NotificationService
  ) { }

  ngOnInit() {
    this.containerId = +this.route.snapshot.paramMap.get('containerId');
    this.getContainer();
    this.getAllJurisdictions();
  }

  selectAllJurisdictions(): void {
    this.selectedJurisdictions = this.jurisdictionsListToDisplay;
  }

  deSelectAllJurisdictions(): void {
    this.selectedJurisdictions = [];
  }

  disallowAllJursForContainer(): void {
    this.modalService.confirmationModal('Do you want to disallow all jurisdictions?', '(Make sure you submitted all other changes before you approve, otherwise they will be lost)').subscribe(
      res => {
        if (res) {
          this._containerService.disallowAllJursForContainer(this.containerId).subscribe(() => {
            this.allowedJurisdictions = [];
            this.getContainer();
          });
        }
      }
    );
  }

  disallowJurForContainer(selectedJurisdiction: Jurisdiction): void {
    this.modalService.confirmationModal('Do you want to disallow this jurisdiction?', '(Make sure you submitted all other changes before you approve, otherwise they will be lost)').subscribe(
      res => {
        if (res) {
          this._containerService.disallowJurForContainer(this.containerId, selectedJurisdiction).subscribe(() => {
            this.getContainer();
          });
        }
      }
    );
  };

  save(): void {
    this.container.selected_jurisdictions = this.selectedJurisdictions;
    this.modalService.confirmationFionaModal('Please confirm edit').subscribe(
      res => {
        if (res) {
          this._containerService.editContainer(this.container)
            .subscribe(() => {
              this.router.navigate(['/containers', this.containerId]);
            });
        }
      }
    );
  }

  goBack(): void {
    this.router.navigate(['/containers', this.containerId]);
  }

  private getContainer(): void {
    this._containerService.get(this.containerId).subscribe(({ data }: ObjectResponse<Container>) => {
      this.container = data;
      const periodStart = new Date(data.period_start);
      const periodEnd = new Date(data.period_end);
      this.container.period_start = moment(periodStart).utc().format('YYYY-MM-DD');
      this.container.period_end = moment(periodEnd).utc().format('YYYY-MM-DD');
      this.allowedJurisdictions = data.allowed_jurisdictions;
      this.setJurisdictionListToDisplay();
    });
  }

  private setJurisdictionListToDisplay(): void {
    this.jurisdictionsListToDisplay = this.fullJurisdictionsList.filter((jurisdiction) => {
      return !this.allowedJurisdictions.find(allowedJur => allowedJur.countryId === jurisdiction.countryId);
    });
  }

  private getAllJurisdictions(): void {
    this._containerService.getAllJurisdictions().subscribe(
      (results: ListResponse<Jurisdiction>) => {
        this.fullJurisdictionsList = results.data;
        this.setJurisdictionListToDisplay();
      }
    );
  }
}
