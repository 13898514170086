/* eslint-disable camelcase */

import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { ContainerService } from '../../services/container.service';
import { MneService } from '../../services/mne.service';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { Container } from 'src/app/models/container';
import { Currency } from 'src/app/models/currency';
import { Country } from 'src/app/models/country';
import { PrimaryFunction } from 'src/app/models/primary-function';
import { CurrencyScale } from 'src/app/models/enums/currency-scale';

@Component({
  selector: 'app-mne-create',
  templateUrl: './mne-create.component.html',
  styleUrls: []
})
export class MneCreateComponent implements OnInit {

  containerName: string;
  container: Container;
  subscriptions: Subscription[] = [];
  containerId: number;
  isService: number = 1;
  isPlaceholder: number = 0;
  taxYear: number;
  currencies: Currency[];
  scaleEnum = [];
  domiciles: Country[] = [];
  taxJurisdictions: Country[] = [];
  taxJurisdictionForSelectedDomicile: Country;
  primaryFunctions: PrimaryFunction[] = [];
  primaryFunctionId: number;

  mneName: string;
  mneCode: string;
  mneFiscalYearEnd: string;
  mneDescription: string;
  mneCurrencyId: number;
  mnePrimaryFunctionId: number;
  mneDomicileId: number;
  mneTaxJurisdictionId: number;
  mneCurrencyScale: number;

  constructor(
    protected route: ActivatedRoute,
    protected router: Router,
    private _containerService: ContainerService,
    private _mneService: MneService,
  ) {}

  ngOnInit() {
    this.containerId = +this.route.snapshot.queryParamMap.get('container_id');
    this.getContainer();
    this.getCurrencies();
    this.getPrimaryFunctions();
    this.scaleEnum = Object.values(CurrencyScale).filter(key => !isNaN(Number(CurrencyScale[key])));
    this.getCountries();
  }

  save(): void {
    const mne = {
      name: this.mneName,
      code: this.mneCode,
      fiscal_year_end: this.mneFiscalYearEnd,
      description: this.mneDescription,
      upe: true,
      upe_currency_id: this.mneCurrencyId,
      primary_function_id: this.mnePrimaryFunctionId,
      domicile_id: this.mneDomicileId,
      tax_jurisdiction_id: this.mneTaxJurisdictionId,
      container_id: this.containerId,
      upeCurrencyScale: +CurrencyScale[this.mneCurrencyScale]
    };

    this.saveNew(mne);
  }

  private saveNew(data): void {
    this.subscriptions.push(
      this._mneService.save(data)
        .subscribe(() => {
          this.router.navigate(['/containers', this.containerId]);
        })
    );
  }

  goBack(): void {
    this.router.navigate(['/containers', this.containerId]);
  }

  private getContainer() {
    this.subscriptions.push(
      this._containerService.get(this.containerId).subscribe((results) => {
        this.container = results.data;
      }));
  }

  private getCurrencies(): void {
    this.subscriptions.push(
      this._mneService.listEntityCurrency().subscribe(({ data }) => {
        this.currencies = data;
      })
    );
  }

  private getCountries(): void {
    this.subscriptions.push(
      this._mneService.listEntityCountries().subscribe(({ data }) => {
        this.domiciles = data;
        this.taxJurisdictions = data;
      })
    );
  }

  private getPrimaryFunctions(): void {
    this.subscriptions.push(
      this._mneService.listEntityPrimaryFunctions().subscribe(({ data }) => {
        this.primaryFunctions = data;
      })
    );
  }

  listenForDomicileValueChanges(domicileId): void {
    this.taxJurisdictionForSelectedDomicile = this.domiciles.find(domicile => domicile.countryId === Number(domicileId));
    this.mneTaxJurisdictionId = this.taxJurisdictionForSelectedDomicile.countryId;
  }

  getSelectedTaxJurisdictionName(): string {
    return this.taxJurisdictionForSelectedDomicile ?
      this.taxJurisdictionForSelectedDomicile.name + `(${this.taxJurisdictionForSelectedDomicile.isoCode})` : '';
  }

}
