import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { BaseService } from './base.service';
import { Container } from '../models/container';
import { Jurisdiction } from '../models/jurisdiction';
import { ListResponse } from '../models/response';
import { AppConfig } from './app-config';

@Injectable({
  providedIn: 'root'
})
export class ContainerService extends BaseService<Container> {

  constructor(http: HttpClient, protected appConfig: AppConfig) {
    super(http, appConfig);
  }

  protected getResourceUrl(): string {
    return this.appConfig.shouldUsePrincipleApi() ? `${this.principleUrl}/v1/container` : `${this.baseUrl}/v1/container`;
  }

  public getAllJurisdictions(): Observable<ListResponse<Jurisdiction>> {
    return this.http.get<ListResponse<Jurisdiction>>(`${this.baseUrl}/v1/countries/jurisdictions`);
  }

  public disallowJurForContainer(containerId: number, jurisdiction: Jurisdiction): Observable<any> {
    return this.appConfig.shouldUsePrincipleApi() ?
      this.http.post<any>(`${this.getResourceUrl()}/${containerId}/jurisdiction/${jurisdiction.isoCode}/${jurisdiction.countryId}`, {}) :
      this.http.delete<any>(`${this.getResourceUrl()}/${containerId}/allowed-jurisdictions/${jurisdiction.isoCode}/${jurisdiction.countryId}`);
  }

  public disallowAllJursForContainer(containerId: number): Observable<any> {
    return this.appConfig.shouldUsePrincipleApi() ?
      this.http.post<any>(`${this.getResourceUrl()}/${containerId}/jurisdiction`, {}) :
      this.http.delete<any>(`${this.getResourceUrl()}/${containerId}/allowed-jurisdictions`);

  }

  public getMNE(id: number): Observable<any> {
    return this.http.get<any>(`${this.getResourceUrl()}/${id}/mne`);
  }

  public editContainer(container: Container): Observable<any> {
    return this.http.patch<any>(`${this.getResourceUrl()}/${container.container_id}`, container);
  }

  public archiveContainer(containerId: number): Observable<any> {
    return this.appConfig.shouldUsePrincipleApi() ?
      this.http.put<any>(`${this.getResourceUrl()}/${containerId}/archive`, { containerId }) :
      this.http.put<any>(`${this.getResourceUrl()}/actions/archive`, { containerId });
  }

  public rollContainerTaxYear(containerId: number, newTaxYear: number): Observable<any> {
    return this.http.post<any>(`rollingstackplacerholder.xbs.com/v1/roll`, { container: { containerId }, newTaxYear });
  }

  public toggleContainerReportAccess(containerId: number, containerReportAccess: boolean): Observable<void> {
    const reportGenFullAccess = containerReportAccess;

    return this.appConfig.shouldUsePrincipleApi() ?
      this.http.put<any>(`${this.getResourceUrl()}/${containerId}/report-access`, { reportGenFullAccess }) :
      this.http.put<any>(`${this.getResourceUrl()}/${containerId}/report-access`, { containerReportAccess });
  }
}
