import { Component, Input, OnInit } from '@angular/core';
import { Message } from '../../../models/message';

@Component({
  selector: 'app-message-banner',
  templateUrl: './message-banner.component.html',
  styleUrls: ['./message-banner.component.css']
})
export class MessageBannerComponent implements OnInit {
  @Input() messages: Message[];
  constructor() { }

  private navbarHeight: number;
  private errorMessageBannerPosition: number;

  ngOnInit() {
    const navbar = document.querySelector('.navbar');

    this.navbarHeight = navbar ? navbar.clientHeight : null;
  }

  get infoMessages() {
    return this.messages.filter(message => message.type === 'message');
  }

  get errorMessages() {
    const infoMessageBanner = document.querySelector('.message-banner-message');

    // If an error and info message appear at the same time, place the error banner below
    // the info banner so they don't overlap.
    if (infoMessageBanner) {
      this.errorMessageBannerPosition = infoMessageBanner.clientHeight + this.navbarHeight;
    } else {
      this.errorMessageBannerPosition = this.navbarHeight;
    }

    return this.messages.filter(message => message.type === 'error');
  }
}
