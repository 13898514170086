import { Message, MessageAction } from '../models/message';

const filterAllMessagesByType = (messages: Message[], bannerType: 'message' | 'error'): Message[] => {
  return messages.filter(message => message.type !== bannerType);
};

const clearMessage = {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  executeOnMessages(messages, actionPayload) {
    messages.shift();
  }
};

const displayMessage = {
  executeOnMessages(messages, actionPayload) {
    const messageToDisplay = actionPayload;
    messages.push(messageToDisplay);
  }
};

const closeBanner = {
  executeOnMessages(messages, actionPayload) {
    const bannerType = actionPayload;
    messages.shift();
    const messagesFiltered = filterAllMessagesByType(messages, bannerType);
    messages.splice(0, messages.length);
    messagesFiltered.forEach(messageItem => messages.push(messageItem));
  }
};

const messageObserver = (messages: Message[]) => {
  return {
    next: (action: MessageAction) => {
      const actions = { clearMessage, displayMessage, closeBanner };
      actions[action.command].executeOnMessages(messages, action.payload);
    }
  };
};

export default messageObserver;
