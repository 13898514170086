/* eslint-disable camelcase */

import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { UserService } from '../../services/user.service';

@Component({
  selector: 'app-user-create',
  templateUrl: './user-create.component.html',
  styleUrls: ['./user-create.component.css']
})
export class UserCreateComponent implements OnInit {

  email: string;
  firstName: string;
  lastName: string;
  error: any;

  subscriptions: Subscription[] = [];

  constructor(
    private _userService: UserService,
    protected router: Router
  ) { }

  ngOnInit() {
  }

  createNewUser(): void {
    const newUser = {
      email: this.email,
      first_name: this.firstName,
      last_name: this.lastName,
    };
    this.subscriptions.push(
      this._userService.save(newUser).subscribe(() => {
        this.router.navigate(['/users', this.email]);
      },
      (error) => {
        // This is uh...fun?
        this.error = error.error.error;
      })
    );
  }

}
