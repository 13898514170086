import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';

import { catchError } from 'rxjs/operators';
import { of, Observable, throwError } from 'rxjs';
import { SessionService } from '../../services/session.service';
import { NotificationService } from '../../services/notifications.service';
import { AppConfig } from 'src/app/services/app-config';

@Injectable()
export class RequestErrorInterceptor implements HttpInterceptor {
  loginStackUrl: string;

  constructor(
    private _notificationsService: NotificationService,
    private _sessionService: SessionService,
    private _appConfig: AppConfig
  ) {
    this.loginStackUrl = `${window.location.origin}/${this._appConfig.getLoginStackRelativeUrl()}`;
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(catchError((error) => {

      if (error.status === 401) {
        this._sessionService.emptySession();
        window.location.replace(this.loginStackUrl);
        return of(error.message);
      }
      this._notificationsService.notify(error);

      return throwError(error);
    }) as any);
  }
}
