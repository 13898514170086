import { Component, OnDestroy, OnInit } from '@angular/core';
import { Message } from '../models/message';
import { Subscription } from 'rxjs';
import { MessageService } from '../services/message.service';
import messageObserver from '../utils/messageObserver';

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.css']
})
export class LandingComponent implements OnDestroy, OnInit {
  private messages: Message[] = [];
  private subscription: Subscription;

  constructor(private _messageService: MessageService) { }

  ngOnInit(): void {
    this.subscription = this._messageService
      .getMessage()
      .subscribe(messageObserver(this.messages));
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
