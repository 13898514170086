import { Injectable } from '@angular/core';
import { BsModalService, } from 'ngx-bootstrap/modal';
import { ConfirmModalFormComponent } from '../shared/components/modals/confirm-modal-form/confirm-modal-form.component';
import { ConfirmationModalComponent } from '../shared/components/modals/confirmation-modal/confirmation-modal.component';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ModalService {

  constructor(private bsModalService: BsModalService) { }

  confirmationFionaModal(title: string): Observable<boolean> {

    const modalData = {
      title: title
    };

    const bsModalRef = this.bsModalService.show(ConfirmModalFormComponent, { initialState: modalData });

    return new Observable((obs) => {
      return bsModalRef.content.result.subscribe((result: boolean) => obs.next(result));
    });
  }

  confirmationModal(title: string, text: string): Observable<boolean> {
    const modalData = {
      title,
      text
    };
    const bsModalRef = this.bsModalService.show(ConfirmationModalComponent, { initialState: modalData });

    return new Observable((obs) => {
      return bsModalRef.content.result.subscribe((result: boolean) => obs.next(result));
    });
  }

  customModal(modalComponent, modalData): Observable<boolean> {

    const bsModalRef = this.bsModalService.show(modalComponent, { initialState: modalData });

    return new Observable((obs) => {
      return bsModalRef.content.result.subscribe((result) => obs.next(result));
    });
  }
}