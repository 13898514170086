import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MessageBannerComponent } from './components/message-banner/message-banner.component';
import { MessageBannerContentComponent } from './components/message-banner/message-banner-content/message-banner-content.component';
import { ConfirmModalFormComponent } from './components/modals/confirm-modal-form/confirm-modal-form.component';
import { ConfirmationModalComponent } from './components/modals/confirmation-modal/confirmation-modal.component';
import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [
    MessageBannerComponent,
    MessageBannerContentComponent,
    ConfirmModalFormComponent,
    ConfirmationModalComponent
  ],
  exports: [
    MessageBannerComponent
  ],
  imports: [
    CommonModule,
    FormsModule
  ],
  entryComponents: [
    ConfirmModalFormComponent,
    ConfirmationModalComponent
  ]
})
export class SharedModule { }
