import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { SessionService } from '../../services/session.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private _sessionService: SessionService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token = this._sessionService.getIdToken();

    if (req.method === 'POST' || req.method === 'PATCH' || req.method === 'PUT') {
      req.headers.set('Content-Type', 'application/json');
    }

    // Do not add auth token if request has HttpRequest is using X-Amz-Algorithm
    if (req.url.search(/X-Amz/gi) !== -1) {
      return next.handle(req);
    }

    if (token) {
      const cloned = req.clone({
        headers: req.headers.set('Authorization', 'Bearer ' + token),
      });

      return next.handle(cloned);
    }

    req.headers.set('Origin', window.location.origin);
    return next.handle(req);
  }
}
