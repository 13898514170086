/* eslint-disable camelcase */

import { ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

import { Container } from '../../models/container';
import { Tenant } from '../../models/tenant';
import { User } from '../../models/user';
import { ProductService } from '../../services/product.service';
import { RoleService } from '../../services/role.service';
import { UserService } from '../../services/user.service';
import { UserRoleService } from '../../services/user-role.service';
import { TenantService } from '../../services/tenant.service';
import { QueryString } from '../../utils/queryString';
import { Jurisdiction } from 'src/app/models/jurisdiction';
import { ListResponse } from 'src/app/models/response';
import { Product } from 'src/app/models/product';
import { MessageService } from 'src/app/services/message.service';
import { LaunchDarklyService } from 'src/app/services/launch-darkly.service';
import * as XbsEnums from '@xbs/xbs-enums';

interface selectOptions {
  value: string | number
  name: string
  disabled?: boolean
}
@Component({
  selector: 'app-user',
  templateUrl: './user-detail.component.html',
  styleUrls: ['./user-detail.component.css']
})

export class UserDetailComponent implements OnInit {

  userId: string;
  user: User;
  userContainers: Container[] = [];
  role: string = '';
  product: Product;

  tenants: Tenant[] = [];
  tenantContainers: Container[] = [];

  selectedTenantId: number;
  selectedContainer;
  selectedTenant: [];

  selectedJurisdictions: Jurisdiction[] = [];
  jurisdictionsListToDisplay: Jurisdiction[] = [];
  fullJurisdictionsList: Jurisdiction[] = [];

  subscriptions: Subscription[] = [];

  licenseAgreementByProduct;

  misc = { placeholderSelector: { value: '', name: 'Select One', disabled: true } };
  products: Array<selectOptions>;
  roles: Array<selectOptions> = [];

  roleWillBeCreated: boolean;

  tenantSearch: FormControl = new FormControl();
  filterString = '';

  constructor(
    private _productService: ProductService,
    private _roleService: RoleService,
    private _userService: UserService,
    private _userRoleService: UserRoleService,
    private _tenantService: TenantService,
    private _messageService: MessageService,
    private _launchDarklyService: LaunchDarklyService,
    protected route: ActivatedRoute
  ) {
    this.subscriptions.push(this._launchDarklyService.flagChange.subscribe(() => {
      this.onFlagsChange();
    }));
  }

  ngOnInit() {
    this.product = this.misc.placeholderSelector;
    this.products = [this.misc.placeholderSelector];
    this.roles = [this.misc.placeholderSelector];
    this.roleWillBeCreated = true;
    this.userId = this.route.snapshot.paramMap.get('userId');
    this.getUser();
    this.getUserContainers();
    this.getAllJurisdictions();
    this.getTenants();
    this.getProducts();
    this.tenantSearch.valueChanges.pipe(
      debounceTime(500),
      distinctUntilChanged()
    ).subscribe(result => {
      this.filterString = result;
      this.getTenants();
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => {
      sub.unsubscribe();
    });
  }

  private onFlagsChange() {
    this.productSelected();
  }

  private getUser() {
    this.subscriptions.push(
      this._userService.get(this.userId).subscribe((results) => {
        this.user = results.data;
        this.user.userUuid = results.data.userUuid;
      })
    );
  }

  disableUser() {
    this.subscriptions.push(
      this._userService.disableUser(this.userId, this.user.email).subscribe(() => {
        this.getUser();
      })
    );
  }

  enableUser() {
    this.subscriptions.push(
      this._userService.enableUser(this.userId, this.user.email).subscribe(() => {
        this.getUser();
      })
    );
  }

  createCognitoUser() {
    this.subscriptions.push(
      this._userService.createCognitoUser(this.userId).subscribe(() => {
        this.getUser();
      })
    );
  }

  resetPassword() {
    this.subscriptions.push(
      this._userService.resetPassword(this.user.email).subscribe(() => { })
    );
  }

  private getUserContainers() {
    this.subscriptions.push(
      this._userService.listUserContainers(this.userId).subscribe((results) => {
        this.userContainers = results.data;
      })
    );
  }

  private getTenants() {
    if (this.filterString) {
      const query = `?${QueryString.getPagination([
        { variableName: 'length', value: 50 },
        { variableName: 'page', value: 1 },
        { variableName: 'search', value: encodeURIComponent(this.filterString) }
      ])}`;
      this.subscriptions.push(
        this._tenantService.list(query).subscribe((results) => {
          this.tenants = results.data.data;
        })
      );
    } else {
      this.tenants = [];
    }
    this.selectedTenant = undefined;
    this.selectedContainer = undefined;
    this.tenantContainers = [];
  }

  selectTenant(tenantId: number) {
    this.tenantContainers = [];
    this.getTenantContainers(tenantId);
  }

  private getTenantContainers(tenantId: number | 'undefined') {
    if (tenantId !== 'undefined') {
      this.subscriptions.push(
        this._tenantService.listTenantContainers(tenantId).subscribe((results) => {
          const userContainers = this.userContainers.map(container => container.container_uuid);
          this.tenantContainers = results.data.filter(tenantContainer => !userContainers.includes(tenantContainer.container_uuid));
        })
      );
    } else {
      this.selectedContainer = undefined;
      this.tenantContainers = [];
    }
  }

  createUserContainer() {
    this.subscriptions.push(
      this._userService.createUserContainer(this.userId, this.user.userUuid, this.selectedContainer).subscribe(() => {
        this.selectedContainer = undefined;
        this.getUserContainers();
      })
    );
    this.tenantContainers = this.tenantContainers.filter(tenantContainer => tenantContainer.container_uuid !== this.selectedContainer.container_uuid);
  }

  updateUserRole() {
    this.subscriptions.push(
      this._userService.updateUserRole(this.userId, this.role, this.user.userUuid).subscribe(() => {
        this.getUser();
      })
    );
  }

  createUserRole() {
    this.subscriptions.push(
      this._userService.createUserRole(this.userId, this.role, this.product.value, this.user.userUuid).subscribe(() => {
        this.getUser();
        this.roleWillBeCreated = false;
      })
    );
  }

  deleteUserContainer(containerSelected) {
    this.subscriptions.push(
      this._userService.deleteUserContainer(this.userId, this.user.userUuid, containerSelected).subscribe(() => {
        this.getUserContainers();
      })
    );
  }

  getProducts() {
    this.subscriptions.push(
      this._productService.listProducts().subscribe(products => {
        const newProducts = products.data.map(record => ({ value: record.productId, name: record.name }));
        newProducts.unshift(this.misc.placeholderSelector);
        this.products = newProducts;
      })
    );
  }

  getEulaByProduct() {
    if (this.product.value !== '') {
      this.subscriptions.push(
        this._userService.getEulaByProduct(this.userId, this.product.value).subscribe(response => {
          this.licenseAgreementByProduct = response.data;
        })
      );
    } else {
      this.licenseAgreementByProduct = false;
    }
  }

  getJurisdictionsByProduct() {
    if (this.product.value !== '') {
      this.subscriptions.push(
        this._userService.getJurisdictionsByProduct(this.user.userUuid, this.product.value).subscribe(response => {
          this.selectedJurisdictions = response.data;
        })
      );
    } else {
      this.selectedJurisdictions = [];
    }
  }

  productSelected() {
    this.getRolesByProduct();
    this.getEulaByProduct();
    this.getJurisdictionsByProduct();
  }

  private resetSelectedRole() {
    if (this.role !== '' && !this.roles.some((role) => role.value === this.role)) {
      this.role = '';
    }
  }

  private filterProvisionRolesUsingFeatureFlags() {
    const hiddenRoles = this._launchDarklyService.flags['admin-app-hide-user-roles'] || [];
    this.roles = this.roles.filter((role) => !hiddenRoles.includes(role.name));
  }

  getRolesByProduct() {
    if (this.product.value !== '') {
      this.subscriptions.push(
        this._roleService.getAllRolesByProduct(this.product.value).subscribe(roles => {
          this.roles = roles.data.map(record => ({ value: record.roleUuid, name: record.roleLabel, disabled: record.disabled }));
          this.roles.unshift(this.misc.placeholderSelector);

          if (this.product.name === XbsEnums.ProductNames.ByName.Provision.Name) {
            this.filterProvisionRolesUsingFeatureFlags();
          }

          this.subscriptions.push(this._userRoleService.getUserRoleByProduct(this.user.userUuid, this.product.value).subscribe(response => {
            this.role = response.data.roleUuid ? response.data.roleUuid : '';
            this.roleWillBeCreated = !(response.data.roleUuid);

            if (this.product.name === XbsEnums.ProductNames.ByName.Provision.Name) {
              this.resetSelectedRole();
            }
          }));
        })
      );
    } else {
      this.roles = [this.misc.placeholderSelector];
      this.role = '';
      this.selectedJurisdictions = [];
    }
  }

  private getAllJurisdictions(): void {
    this._userService.getAllJurisdictions().subscribe(
      (results: ListResponse<Jurisdiction>) => {
        this.fullJurisdictionsList = results.data;
      }
    );
  }

  selectAllJurisdictions(): void {
    this.selectedJurisdictions = this.fullJurisdictionsList;
  }

  unSelectAllJurisdictions(): void {
    this.selectedJurisdictions = [];
  }

  updateUserJurisdictions() {
    this.subscriptions.push(
      this._userService.updateJurisidictionsByProduct(this.user.userUuid, this.product.name, this.product.value, this.selectedJurisdictions).subscribe());
  }
}
