import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { Message } from '../models/message';
import { MessageService } from '../services/message.service';

@Component({
  template: ''
})
export class LoginComponent implements OnInit, OnDestroy {

  private subscriptions = [];
  private messages: Message[] = [];
  isLoggedIn: boolean;
  email: string;
  password: string;

  constructor(private _authService: AuthService, private _router: Router, private _messageService: MessageService) { }

  ngOnInit() {
    this.checkIfLoggedIn();
    if (this.isLoggedIn) {
      this._router.navigate(['']);
    } else {
      this._router.navigate(['login-ui']);
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => {
      sub.unsubscribe();
    });
  }

  checkIfLoggedIn(): void {
    if (this._authService.isLoggedIn()) {
      this.isLoggedIn = true;
    } else {
      this.isLoggedIn = false;
    }
  }

}
