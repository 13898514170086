import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BaseService } from './base.service';
import { User } from '../models/user';
import { AppConfig } from './app-config';
@Injectable({
  providedIn: 'root'
})
export class IdpService extends BaseService<User> {

  constructor(http: HttpClient, protected appConfig: AppConfig) {
    super(http, appConfig);
  }

  protected getResourceUrl(): string {
    return `${this.baseUrl}/v1/idp`;
  }

  public registerIdp(form: FormData): Observable<any> {
    return this.http.post(`${this.getResourceUrl()}`, form);
  }

  public getAllIdps(queryString: String): Observable<any> {
    let resourceUrl = this.getResourceUrl();
    if (!!queryString) {
      resourceUrl += queryString;
    }
    return this.http.get(resourceUrl);
  }

  public updateIdpSsoConfiguration(idpSsoConfiguration): Observable<any> {
    return this.http.patch(`${this.getResourceUrl()}`, idpSsoConfiguration);
  }

}
