import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER } from '@angular/core';

import { HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { HttpClientModule } from '@angular/common/http';
import { NgSelectModule } from '@ng-select/ng-select';
import { TenantsModule } from './tenants/tenants.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HomeComponent } from './home/home.component';
import { HeaderComponent } from './header/header.component';
import { LandingComponent } from './landing/landing.component';
import { UsersComponent } from './users/users.component';
import { UserCreateComponent } from './users/user-create/user-create.component';
import { ContainerComponent } from './container/container.component';
import { MneCreateComponent } from './mne/mne-create/mne-create.component';
import { ContainerDetailComponent } from './container/container-detail/container-detail.component';
import { ContainerCreateComponent } from './container/container-create/container-create.component';
import { UserDetailComponent } from './users/user-detail/user-detail.component';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { WtpMenuComponent } from './wtp/wtp-menu/wtp-menu.component';
import { WtpReviewComponent } from './wtp/wtp-review/wtp-review.component';
import { WtpReviewTypeComponent } from './wtp/wtp-review-type/wtp-review-type.component';
import { WtpReviewCreateComponent } from './wtp/wtp-review-create/wtp-review-create.component';
import { WtpReviewTypeCreateComponent } from './wtp/wtp-review-type-create/wtp-review-type-create.component';
import { WtpReviewEditComponent } from './wtp/wtp-review-edit/wtp-review-edit.component';
import { WtpReviewTypeEditComponent } from './wtp/wtp-review-type-edit/wtp-review-type-edit.component';
import { AuthInterceptor } from './core/interceptors/auth.interceptor';
import { RequestErrorInterceptor } from './core/interceptors/request.interceptor';
import { NotificationService } from './services/notifications.service';
import { AppConfig } from './services/app-config';
import { FileLoaderService } from './services/file-loader.service';
import { ContainerEditComponent } from './container/container-edit/container-edit.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { SharedModule } from './shared';
import { MessageInterceptor } from './core/interceptors/message.interceptor';
import { ModalModule } from 'ngx-bootstrap/modal';
import { IdpComponent } from './idp/idp.component';
import { EditIdpModalComponent } from './idp/edit-idp/edit-idp-modal.component';
import { LaunchDarklyService } from './services/launch-darkly.service';

export function httpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

export function initializeApp(appConfig: AppConfig) {
  return () => appConfig.loadFromFile();
}

@NgModule({
  declarations: [
    AppComponent,
    EditIdpModalComponent,
    LoginComponent,
    HomeComponent,
    HeaderComponent,
    LandingComponent,
    UsersComponent,
    UserCreateComponent,
    ContainerComponent,
    ContainerDetailComponent,
    ContainerCreateComponent,
    IdpComponent,
    UserDetailComponent,
    MneCreateComponent,
    WtpMenuComponent,
    WtpReviewComponent,
    WtpReviewTypeComponent,
    WtpReviewCreateComponent,
    WtpReviewTypeCreateComponent,
    WtpReviewEditComponent,
    WtpReviewTypeEditComponent,
    ContainerEditComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    NgxPaginationModule,
    TenantsModule,
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule,
    SharedModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: httpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    ModalModule.forRoot(),
  ],
  providers: [
    LaunchDarklyService,
    FileLoaderService,
    AppConfig,
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      deps: [AppConfig],
      multi: true,
    },
    NotificationService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RequestErrorInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MessageInterceptor,
      multi: true,
    },
  ],
  entryComponents: [
    EditIdpModalComponent
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
