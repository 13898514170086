export class QueryString {
  static getPagination(options: Array<Record<string, any>>): string {
    let queryString: string;
    const queryParameters = options.map(option => {
      return option.value ? `${option.variableName}=${option.value}` : null;
    });
    if (queryParameters.length > 0) {
      queryString = `${queryParameters.filter(value => value).join('&')}`;
    }
    return queryString;
  }
}
