import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BaseService } from './base.service';
import { Tenant } from '../models/tenant';
import { AppConfig } from './app-config';

@Injectable({
  providedIn: 'root'
})
export class TenantService extends BaseService<Tenant> {

  constructor(http: HttpClient, protected appConfig: AppConfig) {
    super(http, appConfig);
  }

  public update(tenantId: number, data: any): Observable<any> {
    const resourceUrl = `${this.getResourceUrl()}/${tenantId}`;
    return this.http.patch(resourceUrl, data);
  }

  public listTenantContainers(tenantId: number): Observable<any> {
    const resourceUrl = `${this.getResourceUrl()}/${tenantId}/container`;
    return this.http.get(resourceUrl);
  }

  public listTenantUsers(tenantId: number): Observable<any> {
    const resourceUrl = `${this.getResourceUrl()}/${tenantId}/user`;
    return this.http.get(resourceUrl);
  }

  protected getResourceUrl(): string {
    return `${this.baseUrl}/v1/tenant`;
  }
}
